"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_property_decorator_1 = require("vue-property-decorator");
var BaseView_1 = require("@shared/views/BaseView");
var DeckFilters = /** @class */ (function (_super) {
    __extends(DeckFilters, _super);
    function DeckFilters() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.visible = false;
        return _this;
    }
    DeckFilters.prototype.checkForFilters = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        this.visible = false;
        if (((_a = this.searchRequest) === null || _a === void 0 ? void 0 : _a.criteria) || ((_c = (_b = this.searchRequest) === null || _b === void 0 ? void 0 : _b.pros) === null || _c === void 0 ? void 0 : _c.length) || ((_e = (_d = this.searchRequest) === null || _d === void 0 ? void 0 : _d.companies) === null || _e === void 0 ? void 0 : _e.length) || ((_g = (_f = this.searchRequest) === null || _f === void 0 ? void 0 : _f.artists) === null || _g === void 0 ? void 0 : _g.length) || ((_j = (_h = this.searchRequest) === null || _h === void 0 ? void 0 : _h.categories) === null || _j === void 0 ? void 0 : _j.length) || ((_l = (_k = this.searchRequest) === null || _k === void 0 ? void 0 : _k.technologies) === null || _l === void 0 ? void 0 : _l.length) || ((_m = this.searchRequest) === null || _m === void 0 ? void 0 : _m.categories.length)) {
            this.visible = true;
        }
    };
    DeckFilters.prototype.reset = function () {
        this.scroll.scrollToTop();
        this.searchRequest.pageIndex = 0;
        this.searchRequest.companyIds = this.searchRequest.companies.map(function (x) { return x.id; });
        this.searchRequest.technologyIds = this.searchRequest.technologies.map(function (x) { return x.id; });
        this.searchRequest.artistIds = this.searchRequest.artists.map(function (x) { return x.id; });
        this.searchRequest.proIds = this.searchRequest.pros.map(function (x) { return x.id; });
        this.searchRequest.categoryIds = this.searchRequest.categories.map(function (x) { return parseInt(x.id.toString(), null); });
        // STORE SELECTED FILTERS IN LOCAL STORAGE
        this.storage.store('deck-filter', this.searchRequest);
        this.$router.push({ name: 'deck-list', query: this.searchRequest.collapse() }).catch(function () { });
    };
    DeckFilters.prototype.removeCompany = function (item) {
        var index = this.searchRequest.companies.indexOf(item);
        if (index > -1) {
            this.searchRequest.companies.splice(index, 1);
        }
        this.reset();
    };
    DeckFilters.prototype.removeArtist = function (item) {
        var index = this.searchRequest.artists.indexOf(item);
        if (index > -1) {
            this.searchRequest.artists.splice(index, 1);
        }
        this.reset();
    };
    DeckFilters.prototype.removePro = function (item) {
        var index = this.searchRequest.pros.indexOf(item);
        if (index > -1) {
            this.searchRequest.pros.splice(index, 1);
        }
        this.reset();
    };
    DeckFilters.prototype.removeTechnology = function (item) {
        var index = this.searchRequest.technologies.indexOf(item);
        if (index > -1) {
            this.searchRequest.technologies.splice(index, 1);
        }
        this.reset();
    };
    DeckFilters.prototype.removeSeries = function (item) {
        var index = this.searchRequest.series.indexOf(item);
        if (index > -1) {
            this.searchRequest.series.splice(index, 1);
        }
        this.reset();
    };
    DeckFilters.prototype.removeCategory = function (item) {
        var index = this.searchRequest.categories.indexOf(item);
        if (index > -1) {
            this.searchRequest.categories.splice(index, 1);
        }
        this.reset();
    };
    __decorate([
        vue_property_decorator_1.Prop({ default: null })
    ], DeckFilters.prototype, "searchRequest", void 0);
    __decorate([
        vue_property_decorator_1.Watch('searchRequest', { immediate: true, deep: true })
    ], DeckFilters.prototype, "checkForFilters", null);
    DeckFilters = __decorate([
        vue_property_decorator_1.Component({})
    ], DeckFilters);
    return DeckFilters;
}(BaseView_1.BaseView));
exports.default = DeckFilters;
